import { useRouter } from "next/navigation";
import React, { useCallback, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import loginStyle from "./Login.module.scss";
import { useAuth, useSession } from "@tailor-platform/auth/client";
import { LoadStatusBackdrop } from "@/components/LoadStatusBackdrop";
import { ApolloError } from "@apollo/client";

type FormInput = {
  password: string;
  employeeNumber: string;
};

const schema = yup.object({
  employeeNumber: yup.string().required("社員番号を入力して下さい"),
  password: yup
    .string()
    .required("パスワードを入力して下さい")
    .matches(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/, "半角で入力してください"),
});

const Login = () => {
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<ApolloError>();
  const { login } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormInput>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const session = useSession({
    required: false,
  });

  useEffect(() => {
    if (session?.token) {
      router.push("/");
    }
  }, [router, session]);

  const onSubmit = useCallback<SubmitHandler<FormInput>>(
    async (form) => {
      try {
        const isLocal =
          !process.env.NEXT_PUBLIC_ENVIRONMENT ||
          process.env.NEXT_PUBLIC_ENVIRONMENT === "local"
            ? true
            : false;

        setLoading(true);
        if (isLocal) {
          await login({
            name: "default",
            options: {
              email: form.employeeNumber,
              redirectPath: "/",
            },
          });
        } else {
          await login({
            name: "cognito",
            options: {
              id: form.employeeNumber,
              password: form.password,
              redirectPath: "/",
            },
          });
        }
        setLoading(false);
      } catch (err: unknown) {
        setLoading(false);
        let message = "予期せぬエラーが発生しました";
        if (err instanceof Error) {
          // For simplicity, here intentionally ignores the rare case that multiple error codes are responded.
          message = err.message;
        }
        setSubmitError(new ApolloError({ errorMessage: message }));
      }
    },
    [login],
  );

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = useCallback(
    () => setShowPassword(!showPassword),
    [showPassword],
  );
  const handleMouseDownPassword = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault(),
    [],
  );

  return (
    <Container className={loginStyle.login}>
      <Stack className={loginStyle.loginContainer}>
        <LoadStatusBackdrop
          loading={loading}
          error={submitError}
          onCloseSnackbar={setSubmitError}
        />
        <Box className="title-container">
          <Typography variant="h2">ログイン</Typography>
        </Box>
        <Box className={loginStyle.formContainer}>
          <TextField
            className="employeenumber-form"
            label="社員番号"
            {...register("employeeNumber")}
            error={"employeeNumber" in errors}
            helperText={errors.employeeNumber?.message}
          />
          <FormControl variant="outlined" fullWidth>
            <InputLabel error={"password" in errors}>パスワード</InputLabel>
            <OutlinedInput
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="パスワード"
              {...register("password")}
              error={"password" in errors}
            />
            <FormHelperText error>{errors.password?.message}</FormHelperText>
          </FormControl>
        </Box>
        <Box className={loginStyle.buttonContainer}>
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="action"
            size="large"
            fullWidth
            disableElevation
            disabled={!isValid}
          >
            ログイン
          </Button>
        </Box>

        <Box sx={{ textAlign: "center" }}>
          <Typography
            fontSize="14px"
            color="primary"
            component={Link}
            href={"https://sdh-webkintai.zendesk.com/hc/ja"}
          >
            ヘルプページはこちら
          </Typography>

          <Typography fontSize="12px">
            ※操作マニュアルや最新の情報 （不具合、改善等）を公開しています
          </Typography>
        </Box>
      </Stack>
    </Container>
  );
};

export default Login;
