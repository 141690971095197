import { ApolloError } from "@apollo/client";
import { Alert, Backdrop, CircularProgress, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";

interface LoadStatusBackdropProps {
  loading: boolean;
  error?: ApolloError;
  onCloseSnackbar?: any;
}

type HashMap<T> = { [key: string]: T };

const fieldMap: HashMap<string> = {
  createWorkrecord: "勤怠申請",
  login: "ログイン",
  updatePassword: "パスワード変更",
  "createWorkrecord,site-manager-approve": "勤怠申請",
  "createWorkrecord,sales-office-manager-approve": "勤怠申請",
  "createWorkrecord,clerk-approve": "勤怠申請",
  "createWorkrecord,area-manager-approve": "勤怠申請",
  "updateWorkrecord,site-manager-approve": "勤怠更新",
  "updateWorkrecord,sales-office-manager-approve": "勤怠更新",
  "updateWorkrecord,clerk-approve": "勤怠更新",
  "updateWorkrecord,area-manager-approve": "勤怠更新",
  CreateSalesOfficeManagerAssign: "営業所と営業所長の関連付け登録",
  UpdateSalesOfficeManagerAssign: "営業所と営業所長の関連付け更新",
  DeleteSalesOfficeManagerAssign: "営業所と営業所長の関連付け削除",
  CreateSalesOfficeClerkAssign: "営業所と事務担当の関連付け登録",
  UpdateSalesOfficeClerkAssign: "営業所と事務担当の関連付け更新",
  DeleteSalesOfficeClerkAssign: "営業所と事務担当の関連付け削除",
  CreateContractSiteManagerAssign: "契約と現場責任者の関連付け登録",
  UpdateContractSiteManagerAssign: "契約と現場責任者の関連付け更新",
  DeleteContractSiteManagerAssign: "契約と現場責任者の関連付け削除",
  CreateContractAreaManagerAssign: "契約とAMの関連付け登録",
  UpdateContractAreaManagerAssign: "契約とAMの関連付け更新",
  DeleteContractAreaManagerAssign: "契約とAMの関連付け削除",
  applyWorkRecord: "勤怠申請",
};

const messageMap: HashMap<HashMap<string>> = {
  createWorkrecord: {
    AlreadyExists: "既に登録されています、日付と契約コードを見直してください",
  },
  login: {
    LoginFail: "IDかパスワードが違います",
  },
  updatePassword: {
    UserPasswordUpdateFail: "現在のパスワードが違います確認してください。",
  },
};

const commonMessageMap: HashMap<string> = {
  WorkflowAssignError: "承認者の設定が不正です",
  AlreadyExists: "重複登録エラーです",
  InvalidValue: "不正な値です",
  Required: "必須項目が未入力です",
  InternalError: "エラーが発生しました",
  InternalServerError: "エラーが発生しました",
};

export const LoadStatusBackdrop = ({
  loading,
  error,
  onCloseSnackbar,
}: LoadStatusBackdropProps) => {
  const [open, setOpen] = useState(false);
  useEffect(() => setOpen(loading || error !== undefined), [loading, error]);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    if (onCloseSnackbar) onCloseSnackbar();
  };

  const showErrors = error?.graphQLErrors
    ? error.graphQLErrors
        .filter((e) => e.extensions && e.extensions["code"])
        .map((gerr) => {
          const key = gerr.path?.join(",") || "";
          const code = gerr.extensions
            ? (gerr.extensions["code"] as string)
            : "";
          const field = fieldMap[key] ? fieldMap[key] : key;
          let message =
            messageMap[key] && messageMap[key][code]
              ? messageMap[key][code]
              : commonMessageMap[code] || gerr.message;

          // 例外的に承認権限がない場合のエラーメッセージを変更
          //チケット https://tailor.atlassian.net/browse/SDH-1376
          if (message.includes("input: moveState approvers are not match")) {
            message = "承認権限がありません";
            return message;
          }

          return field ? `${field}:${message}` : message;
        })
    : [];
  return (
    <Backdrop
      onClick={handleClose}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      {loading && <CircularProgress />}
      {error !== undefined && error?.networkError != null ? (
        <Snackbar open={true} autoHideDuration={3000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            elevation={6}
            variant="filled"
            severity="error"
          >
            ネットワークに接続できません({error?.networkError?.message})
          </Alert>
        </Snackbar>
      ) : showErrors.length > 0 ? (
        <Snackbar open={true} autoHideDuration={3000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            elevation={6}
            variant="filled"
            severity="error"
          >
            {showErrors.map((gerr, i) => (
              <span key={`error_${i}`}>{gerr}</span>
            ))}
          </Alert>
        </Snackbar>
      ) : error !== undefined ? (
        <Snackbar open={true} autoHideDuration={3000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            elevation={6}
            variant="filled"
            severity="error"
          >
            エラーが発生しました。({error.message})
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
    </Backdrop>
  );
};
